import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import homeLogo from "../../Assets/home-main.svg";
// import Home2 from "../Projects/ProjectItems";
// import Type from "./Type";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Container className="home-content" >
          <Row>
            <Col md={12} className="home-header text-center" >
              <div onMouseOver={() => { document.getElementById('home').classList.remove('home-hovered-out');document.getElementById('home').classList.add('home-hovered') }} onMouseLeave={() => { document.getElementById('home').classList.remove('home-hovered');document.getElementById('home').classList.add('home-hovered-out'); }}>
                <h1>An animator who enjoys creating content.</h1>
                <h2>So let me entertain you for a few!</h2>
              </div>
              {/* <h1 style={{ paddingBottom: 15 }} className="heading">
                Hi There!{" "}
              </h1>

              <h1 className="heading-name">
                I'M
                <strong className="main-name"> LUNA HAJJAR</strong>
              </h1>

              <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div> */}
            </Col>

            {/* <Col md={5} style={{ paddingBottom: 20 }}>
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              />
            </Col> */}
          </Row>
        </Container>
      </Container>
      {/* <Home2 /> */}
    </section>
  );
}

export default Home;
