import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

function ProjectItems({ props }) {
  const params = new URLSearchParams(window.location.search);
  const type = params.get('type');
  let title = type;

  if (type === "bnf") {
    title = "Beauty & Fashion";
  } else if (type === "fnb") {
    title = "Food & Beverages";
  } else if (type === "sports") {
    title = "Sports & Training";
  } else if (type === "medical") {
    title = "Medical, Wellness & Nutrition";
  } else if (type === "art") {
    title = "Art & Decorations";
  } else if (type === "events") {
    title = "Events";
  } else if (type === "tiktok") {
    title = "TikTok";
  } else if (type === "animation") {
    title = "Animation";
  }

  const [listofBlobs, setListOfBlobs] = useState([]);
  const [visibleCount, setVisibleCount] = useState(8); // Number of videos to display initially

  useEffect(() => {
    const containerName = type.replace(/\s/g, '').toLowerCase();
    const url = `https://lunaportfolio-func.azurewebsites.net/api/getblobs/${containerName}?code=QM3vc3--LJ_8gnmN32O_mYYK-54Q4F4xPhxqGnHq7XXHAzFuqU6A-w==`;

    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();
        setListOfBlobs(json);
      } catch (error) {
        setListOfBlobs([]);
      }
    };

    fetchData();
  }, [type]);

  // Function to load more videos
  const loadMore = () => {
    setVisibleCount(prevCount => prevCount + 8);
  };

  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading" style={{ marginBottom: 30 }}>
          <strong>{title}</strong>
        </h1>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {listofBlobs.slice(0, visibleCount).map((x, index) => (
            <Col md={3} key={index}>
              <div className="project-card recent-work-card">
                <video
                  key={`key-${x.customData}`}
                  style={{ maxWidth: '100%', height: 'auto' }}
                  controls
                >
                  <source src={`${x.uri}`} />
                </video>
                <p style={{ textAlign: 'left', marginLeft: 10, paddingBottom: 15 }}>{x.customData}</p>
              </div>
            </Col>
          ))}
        </Row>
        {visibleCount < listofBlobs.length && (
          <div style={{ textAlign: 'center', marginTop: 20 }}>
            <button onClick={loadMore}   style={{backgroundColor:'#000',padding:'5px 20px',borderRadius:5,borderColor:'transparent',color:'#fff'}}>
              Load More
            </button>
          </div>
        )}
      </Container>
    </Container>
  );
}

export default ProjectItems;