import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0" style={{ display: 'flex', }}>
          <span style={{ textAlign: 'justify' }}>
          I’m Luna Hajjar, a 2D Animator with both a Bachelor’s and Master’s degree from ALBA University. I started my career in social media agencies, where I developed expertise in creating advertising and animated videos, crafting content that captivates audiences on social media platforms.
            <br />
            <br />
            I also had the privilege of teaching "The Reel Deal" at Dekmak, a course designed to provide business owners with practical skills in content creation, trending transitions, video editing, and strategic social media usage.
            <br />
            <br />
            Today, I work as a freelance content creator, specializing in storytelling, brand visibility, and product promotion through engaging, impactful content. Alongside this, I also run my own brand, @aytchell.
          </span>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
