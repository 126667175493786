import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiAdobeaftereffects,
  SiAdobepremierepro,
  SiAdobephotoshop,

} from "react-icons/si";
import capcut from '../../Assets/capcut.256x192.png'



function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiAdobeaftereffects />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAdobepremierepro />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAdobephotoshop />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src={capcut} width={70} />
      </Col>
    </Row>
  );
}

export default Techstack;
