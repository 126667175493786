import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import animation from "../../Assets/Projects/animation.jpg";
import fnb from "../../Assets/Projects/fnb.jpg";
import fashion from "../../Assets/Projects/fashion.jpg";
import gym from "../../Assets/Projects/gym.jpg";
import medical from "../../Assets/Projects/medical.jpg";
import art from "../../Assets/Projects/art.jpg";
import events from "../../Assets/Projects/events.jpg";
import tiktok from "../../Assets/Projects/tiktok.jpg";
import { Link } from "react-router-dom";

function Projects({ navigation }) {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading" >
          My Recent Work
        </h1>
        <p >
          Here are a few projects I've worked on recently.
        </p>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <Link to={'/project/items?type=animation'} replace>
              <ProjectCard
                imgPath={animation}
                isBlog={false}
                title="Animation"
                description="Bring your static designs to life and create an engaging and memorable user experience."
              />
            </Link>
          </Col>


          <Col md={4} className="project-card">
            <Link to={'/project/items?type=fnb'} replace>
              <ProjectCard
                imgPath={fnb}
                isBlog={false}
                title="Food &amp; Beverages"
                description="Bring your static designs to life and create an engaging and memorable user experience."
              />
            </Link>
          </Col>

          <Col md={4} className="project-card">
            <Link to={'/project/items?type=bnf'} replace>
              <ProjectCard
                imgPath={fashion}
                isBlog={false}
                title="Beauty &amp; Fashion"
                description="Develop and produce high-quality, relevant, and engaging content for a specific target audience."
              />
            </Link>
          </Col>

          <Col md={4} className="project-card">
            <Link to={'/project/items?type=sports'} replace>
              <ProjectCard
                imgPath={gym}
                isBlog={false}
                title="Sports &amp; Training"
                description="Brands/accounts with which I have collaborated."
              />
            </Link>
          </Col>

          <Col md={4} className="project-card">
            <Link to={'/project/items?type=medical'} replace>
              <ProjectCard
                imgPath={medical}
                isBlog={false}
                title="Medical, Wellness &amp; Nutrition"
                description="Here are videos that I produced on the spur of the moment. Each one of them  represents a specific event."
              />
            </Link>
          </Col>

          <Col md={4} className="project-card">
            <Link to={'/project/items?type=events'} replace>
              <ProjectCard
                imgPath={events}
                isBlog={false}
                title="Events"
                description="I've been giving a course @dekmak.co to help business owners work on their grow their business "
              />
            </Link>
          </Col>

          <Col md={4} className="project-card">
            <Link to={'/project/items?type=art'} replace>
              <ProjectCard
                imgPath={art}
                isBlog={false}
                title="Arts &amp; Decorations"
                description="I've been giving a course @dekmak.co to help business owners work on their grow their business "
              />
            </Link>
          </Col>

          <Col md={4} className="project-card">
            <Link to={'/project/items?type=tiktok'} replace>
              <ProjectCard
                imgPath={tiktok}
                isBlog={false}
                title="Tiktok"
                description="I've been giving a course @dekmak.co to help business owners work on their grow their business "
              />
            </Link>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
